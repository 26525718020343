var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.titleDialog,
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "60%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onHandleClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "contents" },
        [
          _c(
            "el-table",
            { staticStyle: { width: "100%" }, attrs: { data: _vm.authList } },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "模块", "min-width": "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-checkbox", {
                          attrs: { label: scope.row.name },
                          on: {
                            change: function ($event) {
                              return _vm.onChangeModule(scope.$index)
                            },
                          },
                          model: {
                            value: scope.row.isSelected,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "isSelected", $$v)
                            },
                            expression: "scope.row.isSelected",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "child", label: "权限", "min-width": "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(scope.row.child, function (child) {
                        return _c("el-checkbox", {
                          key: child.id,
                          attrs: {
                            disabled: !scope.row.isSelected,
                            label: child.name,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.onChangeChild(child, scope.$index)
                            },
                          },
                          model: {
                            value: child.isSelected,
                            callback: function ($$v) {
                              _vm.$set(child, "isSelected", $$v)
                            },
                            expression: "child.isSelected",
                          },
                        })
                      })
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onHandleClose } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onHandleSubmit } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }