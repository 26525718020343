import axios from "@/api/http";
const TDC_URL = process.env.VUE_APP_TDC_URL; // 城市仓
const DELIVERY_URL = process.env.VUE_APP_DELIVERY_URL; // 自提点

/**
 * 城市仓后台-小程序账号管理-查询权限
 * @param {*} params
 * @returns
 */
export function getTdcUserAuthorityList(params = {}) {
  return axios({
    method: "GET",
    url: `${TDC_URL}/applet/user/authorityList`,
    params,
  });
}

/**
 * 自提点后台-小程序账号管理-查询权限
 * @param {*} params
 * @returns
 */
export function getDeliveryUserAuthorityList(params = {}) {
  return axios({
    method: "GET",
    url: `${DELIVERY_URL}/applet/user/authorityList`,
    params,
  });
}

/**
 * 城市仓后台-小程序账号管理-编辑权限
 * @param {*} params
 * @returns
 */
export function postTdcUserAuthoritySave(params = {}) {
  return axios({
    method: "POST",
    url: `${TDC_URL}/applet/user/authoritySave`,
    data: params,
  });
}

/**
 * 自提点后台-小程序账号管理-编辑权限
 * @param {*} params
 * @returns
 */
export function postDeliveryUserAuthoritySave(params = {}) {
  return axios({
    method: "POST",
    url: `${DELIVERY_URL}/applet/user/authoritySave`,
    data: params,
  });
}
