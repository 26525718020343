import { BASE } from "../index";
import axios from "../http";
const base = BASE.PRO4; // 城市仓
const deliveryBase = BASE.PRO3; // 自提点
/**
 * 城市仓管理-小程序账号管理-删除
 * @param {*} params
 * @returns
 */
export function postTdcUserdel(params = {}) {
  return axios.post(`${base}/applet/user/del`, params);
}

/**
 * 自提点管理-小程序账号管理-删除
 * @param {*} params
 * @returns
 */
export function postDeliveryUserdel(params = {}) {
  return axios.post(`${deliveryBase}/applet/user/del`, params);
}

/**
 * 自提点管理-小程序账号管理-是否接收售后短信
 * @param {*} params
 * @returns
 */
export function postUserSmsStatus(params = {}) {
  return axios.post(`${deliveryBase}/applet/user/smsStatus`, params);
}
